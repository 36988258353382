import { createAsyncThunk } from '@reduxjs/toolkit';
import Raven from 'raven-js';
import * as ApplicationAuthorizationClient from '../data/api/ApplicationAuthorizationClient';
import { EVENTS } from '../constants/Events';
import * as AuthorizeClient from '../data/api/AuthorizeClient';
import * as ApplicationConnectionClient from '../data/api/ApplicationConnectionClient';
import Tracker from '../utils/usageTracker';
import ApplicationAuthorization from '../data/models/ApplicationAuthorization';
import RequestStatus from '../data/models/RequestStatus';
import { getErrorFields } from '../utils/appUtils';
import { checkForNewRequestedScopes } from '../utils/authUtils';
export const authorize = createAsyncThunk('authorize', ({
  data,
  trackerOptions,
  redirectOptions
}) => {
  return AuthorizeClient.authorize(data).then(res => {
    Tracker.track(EVENTS.APP_INSTALLED, Object.assign({
      clientId: data.clientId
    }, trackerOptions));
    if (redirectOptions.allowParentRedirect && redirectOptions.sendMessage) {
      redirectOptions.sendMessage('OAUTH_SUCCESS', {
        redirectUri: res.redirectUri
      });
    } else {
      window.location.href = res.redirectUri;
    }
    return res;
  });
});
export const fetchIsRequestingNewScopes = createAsyncThunk('fetchIsRequestingNewScopes', data => {
  const {
    clientId,
    scopes,
    optionalScopes
  } = data;
  return ApplicationAuthorizationClient.fetchInstalledScopes(clientId).then(({
    results: installedScopes
  }) => checkForNewRequestedScopes([...scopes, ...(optionalScopes || [])], installedScopes));
});
export const fetchInternallyDevelopedApps = createAsyncThunk('fetchInternallyDevelopedApps', () => {
  return ApplicationConnectionClient.fetchInternallyDevelopedApps().then(({
    results
  }) => results);
});
export const fetchApplicationAuthorizationData = createAsyncThunk('fetchApplicationAuthorizationData', ({
  data,
  redirectOptions
}, {
  dispatch,
  rejectWithValue,
  getState
}) => {
  const state = getState();
  return ApplicationAuthorizationClient.fetch(data).then(resp => {
    const app = new ApplicationAuthorization(resp);
    dispatch(fetchInternallyDevelopedApps()).catch(e => Raven.captureException(e));
    if (resp.authorized) {
      Tracker.track(EVENTS.AUTHORIZE_VIEW, {
        applicationId: app.appId,
        applicationName: app.name,
        domainVerificationStatus: app.getDomainVerificationStatus(),
        listingStatus: app.getListingStatus(),
        scopeGroupCount: app.scopeGroups.length,
        scopeGroups: app.scopeGroups.map(sg => sg.name),
        hasInstallPermission: true,
        clientId: app.clientId,
        // If they are authorized, then they will be redirected automatically
        // and are not requesting new scopes
        redirected: true,
        isRequestingNewScopes: false
      });
      dispatch(authorize({
        data,
        trackerOptions: {
          // If they were previously authorized, they have permission to install
          // and are not requesting any new scopes
          hasInstallPermission: true,
          isRequestingNewScopes: false
        },
        redirectOptions
      })).catch(e => Raven.captureException(e));
    }
    return app;
  }).catch(err => {
    const {
      status,
      errorCode,
      subCategory,
      context
    } = getErrorFields(err, state);
    Tracker.track(EVENTS.AUTHORIZE_ALERT_VIEW, {
      Subcategory: subCategory,
      status
    });
    if (err.status >= 400 && err.status < 500) {
      return rejectWithValue({
        status: RequestStatus.warning,
        errorCode,
        subCategory,
        context
      });
    } else {
      return rejectWithValue({
        status: RequestStatus.failed,
        errorCode,
        subCategory,
        context
      });
    }
  });
});

/**
 * This action is used to pass app auth data to the component that consumes
 * the account picker, so that we can display an error message and offer the
 * user a way to reselect an appropriate account. Currently only used by
 * Okta SCIM.
 @param clientId A UUID
 @param redirectUri Redirect path provided by external app
 @param state A key passed through the auth url
 @param hubId Selected portal's id
 @param scopes Scopes enabled for the selected portal
 @param responseType
 */
export const noPortalFetchApplicationAuthorizationData = createAsyncThunk('noPortalFetchApplicationAuthorizationData', ({
  data: {
    clientId,
    redirectUri,
    state,
    hubId,
    hublet,
    scopes,
    optionalScopes,
    responseType
  }
}, {
  rejectWithValue,
  getState
}) => {
  const storeState = getState();
  return ApplicationAuthorizationClient.fetch({
    clientId,
    redirectUri,
    state,
    hubId,
    scopes,
    optionalScopes,
    responseType,
    hublet
  }).then(resp => new ApplicationAuthorization(resp)).catch(err => {
    const {
      errorCode,
      subCategory,
      context
    } = getErrorFields(err, storeState);
    if (err.status >= 400 && err.status < 500) {
      return rejectWithValue({
        status: RequestStatus.warning,
        errorCode,
        subCategory,
        context
      });
    } else {
      return rejectWithValue({
        status: RequestStatus.failed,
        errorCode,
        subCategory,
        context
      });
    }
  });
});
export const fetchAppDetails = createAsyncThunk('fetchAppDetails', clientId => ApplicationConnectionClient.getAppInfoByClientId(clientId));