module.exports = {
  "authorize-view": {
    "name": "authorize-view",
    "class": "view",
    "properties": {
      "applicationId": "number",
      "applicationName": "string",
      "domainVerificationStatus": [
        "NO_DOMAIN",
        "UNVERIFIED_DOMAIN",
        "VERIFIED_DOMAIN"
      ],
      "listingStatus": [
        "UNLISTED",
        "PUBLISHED",
        "CERTIFIED"
      ],
      "scopeGroupCount": "number",
      "scopeGroups": "array",
      "redirected": {
        "type": "boolean"
      },
      "hasInstallPermission": {
        "type": "boolean",
        "isOptional": true
      },
      "clientId": "string",
      "isRequestingNewScopes": {
        "type": "boolean"
      }
    },
    "namespace": "oauth",
    "version": "1"
  },
  "authorize-alert-view": {
    "name": "authorize-alert-view",
    "class": "view",
    "properties": {
      "message": {
        "type": "string",
        "isOptional": true
      },
      "status": "string",
      "Subcategory": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "oauth",
    "version": "1"
  },
  "verified-domain-click": {
    "name": "verified-domain-click",
    "class": "interaction",
    "properties": {
      "applicationId": "number",
      "applicationName": "string",
      "domain": "string"
    },
    "namespace": "oauth",
    "version": "1"
  },
  "install-flow-canceled": {
    "name": "install-flow-canceled",
    "class": "interaction",
    "namespace": "oauth",
    "version": "1"
  },
  "app-installed": {
    "name": "app-installed",
    "class": "interaction",
    "properties": {
      "clientId": {
        "type": "string"
      },
      "hasInstallPermission": {
        "type": "boolean"
      },
      "isRequestingNewScopes": {
        "type": "boolean"
      }
    },
    "namespace": "oauth",
    "version": "1"
  }
};